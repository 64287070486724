/*------------------------------------------------------------------
[Default page]

Name: Default page;

1. [Simple content]

-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Simple content]
*/
.single {

    .single-content.typography {
        font-size: 1.5rem;
        line-height: 1.9;

        @include media-queries(767px) {
            font-size: 1.3rem;
        }

        p {
            margin-bottom: 4rem;

            line-height: 1.9;

            @include media-queries(767px) {
                margin-bottom: 2rem;
            }
        }

        .h4 {
            margin-bottom: 0;
        }

        hr {
            margin-bottom: 4rem;

            @include media-queries(767px) {
                margin-bottom: 2rem;
            }

            + p {
                margin-bottom: 2rem;

                @include media-queries(767px) {
                    margin-bottom: 1rem;
                }
            }
        }

        ul {
            margin-bottom: 3rem;

            list-style: none;

            -webkit-column-count: 2;
            -moz-column-count: 2;
            -o-column-count: 2;
            -ms-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 20px;
            -moz-column-gap: 20px;
            -o-column-gap: 20px;
            -ms-column-gap: 20px;
            column-gap: 20px;

            @include media-queries(767px) {
                margin-bottom: 1.5rem;
            }

            li {
                margin-bottom: 1rem;
            }
        }
    }

    .single-content {
        padding-top: 4.5rem;
        padding-bottom: 8rem;

        @include media-queries(767px) {
            padding: 2rem 3rem;

            p.empty {
                display: none;
            }
        }

        &:before {
            content: '';

            position: absolute;
            top: 1px;
            @include transform(translate(-50%, -100%));

            border-width: 1px 1px 160px 2560px;
            border-color: $background $background $light-blue $background;
        }
    }

    .gallery-single {

        @include media-queries(767px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &__title {
            display: block;

            width: 100%;
        }

        &__item {
            padding: 0.15rem;

            &.col-xs-12 {
                max-width: 33.33333%;

                @include media-queries(767px) {
                    max-width: 50%;
                }
            }
        }

        &__img {
            padding-bottom: 65%;
            width: 100%;

            -webkit-background-size: cover;
            background-size: cover;
        }
    }
}