/*------------------------------------------------------------------
[Typography]

Body copy:      1.2em/1.6em Verdana, Helvetica, Arial, Geneva, sans-serif;
Headers:        2.7em/1.3em Helvetica, Arial, "Lucida Sans Unicode", Verdana, sans-serif;
Input, textarea:    1.1em Helvetica, Verdana, Geneva, Arial, sans-serif;
Sidebar heading:    1.5em Helvetica, Trebuchet MS, Arial, sans-serif;

Notes:  decreasing heading by 0.4em with every subsequent heading level
-------------------------------------------------------------------*/

$regular:       "poppins",sans-serif;
$bold:          "poppins",sans-serif;
$medium:        "poppins",sans-serif;
$Bodoni:        "BodoniSvtyTwoITCTTBold",sans-serif;
$font-size:     62.5%;
$line-height:   1.2343;

@font-face {
    font-family: "BodoniSvtyTwoITCTTBold";
    src: url("../fonts/BodoniSvtyTwoITCTTBold/BodoniSvtyTwoITCTTBold.eot?") format("eot"),
    url("../fonts/BodoniSvtyTwoITCTTBold/BodoniSvtyTwoITCTTBold.woff") format("woff"),
    url("../fonts/BodoniSvtyTwoITCTTBold/BodoniSvtyTwoITCTTBold.ttf") format("truetype"),
    url("../fonts/BodoniSvtyTwoITCTTBold/BodoniSvtyTwoITCTTBold.svg#BodoniSvtyTwoITCTTBold") format("svg");
}

a {
    color: inherit;
    text-decoration: none;
}

.big {
    font-weight: 700;
    font-size: 13rem;

    @include media-queries(767px) {
        font-size: 8rem;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $Bodoni; line-height: 1.3; }
.h1 { letter-spacing: -0.025em; }
.h3, .h4 { }
.h1 { font-size: 12rem;

    @include media-queries(768px) {
        font-size: 10rem;
    }

    @include media-queries(767px) {
        font-size: 3.6rem;
    }
}
.h2 { font-size: 12rem;

    @include media-queries(768px) {
        font-size: 10rem;
    }

    @include media-queries(767px) {
        font-size: 3.6rem;
    }
}
.h3 {
    font-size: 4.4rem; margin: 0 0 2rem 0;

    @include media-queries(767px) {
        font-size: 2.2rem;

        margin-bottom: 1rem;
    }
}
.h4 { font-size: 3.6rem;

    @include media-queries(767px) {
        font-size: 2.1rem;
    }
}
.h5 { font-size: 2.8rem; margin: 0 0 2rem 0; letter-spacing: 0.100em;

    @include media-queries(767px) {
        margin-bottom: 1rem;

        font-size: 1.5rem;
    }
}
.h6 { font-size: 2.4rem; margin: 0 0 2rem 0;

    @include media-queries(767px) {
        margin-bottom: 1rem;

        font-size: 1.3rem;
    }
}


.italic { font-style: italic; }
.bold   { font-weight: 700; }

.block-margins {
    margin: 0 0 2rem 0;

    @include media-queries(768px) {
        margin-bottom: 1rem;
    }
}

.unordered-list { list-style-type: disc; }
.ordered-list { list-style: decimal; }


.typography {
    font-size: 1.6rem;
    color: $blue;

    @include media-queries(767px) {
        font-size: 1.3rem;
    }

    i, em { @extend .italic; }
    b, strong { @extend .bold; }

    h1, .h1 { @extend .h1; margin: 0; }
    h2, .h2 { @extend .h2; margin: 0; }
    h3, .h3 { @extend .h3; }
    h4, .h4 { @extend .h4; margin: 0; }
    h5, .h5 { @extend .h5; }
    h6, .h6 { @extend .h6; }

    p, ul, ol, pre { @extend .block-margins; line-height: 1.5; }

    ul { @extend .unordered-list; }
    ol { @extend .ordered-list; }

    hr {
        border-top-color: $blue;
    }
}