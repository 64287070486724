/*------------------------------------------------------------------
[Book now]

Name: Book now;

Notes: Form book now
-------------------------------------------------------------------*/
.book-now {
    position: relative;

    width: 100%;

    text-align: left;

    margin-bottom: 4.5rem;

    @include media-queries(767px) {
        margin-bottom: 2rem;
    }

    footer & {
        margin-bottom: 4rem;

        @include media-queries(767px) {
            @include box-sizing(border-box);

            padding: 0 3rem;
            margin-bottom: 1rem;
        }
    }

    .book-now__input {
        display: inline-block;
        @include box-sizing(border-box);

        margin-right: 2px;
        padding: 1.3rem 2rem;
        width: calc(40% - 5px);

        background-color: $white;

        font-weight: 700;
        font-size: 1.4rem;
        color: $light-silver;
        text-align: left;

        &.error {
            color: red;
        }

        @include media-queries(767px) {
            margin-right: 0;
            margin-bottom: 0.5rem;

            width: 100%;

            text-align: center;
        }

        footer & {
            margin-right: 0;
        }

        &--submit {
            background-color: $light-blue;

            margin-right: 0;
            width: calc(20%);

            color: $white;
            text-align: center;

            cursor: pointer;

            @include media-queries(767px) {
                width: 100%;
            }
        }
    }
}