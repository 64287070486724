/*------------------------------------------------------------------
[Home]

Name: Description;

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Home content]
*/
.home {

    .section-content {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @include media-queries(768px) {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        @include media-queries(767px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    main {

        section {

            &.small-home {
                margin-top: -0.1rem;
                padding-top: 5rem;

                @include media-queries(768px) {
                    padding-top: 2.5rem;
                }

                &:before {
                    display: none;
                }

                &:after {
                    @include transform(translate(-50%, 50%));
                }
            }
        }
    }

    .content-offers {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;

        @include media-queries(767px) {
            padding: 2rem 3rem;
        }

        .container {
            background-color: $background;

            .row {
                margin-right: 0;

                @include media-queries(767px) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        &__content {
            padding-top: 9rem;
            padding-bottom: 7rem;

            @include media-queries(767px) {
                padding: 3.5rem 3.5rem 2.5rem 3.5rem;
            }

            p {
                line-height: 2;
            }
        }
        
        &__img {
            background: transparent no-repeat center center;
            -webkit-background-size: cover;
            background-size: cover;

            @include media-queries(767px) {
                padding-bottom: 100%;
            }
        }
    }

    .section-banner {
        color: $white;

        padding-top: 4.5rem;
        padding-bottom: 4.5rem;

        @include media-queries(767px) {
            padding: 2rem 0;
        }

        .container {
            background: transparent no-repeat center center;
            -webkit-background-size: cover;
            background-size: cover;
        }

        .row {
            height: 100vh;
            max-height: 650px;

            @include media-queries(768px) {
                padding-bottom: 56.25%;
                height: auto;
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 0;
            @include transform(translate(0, -50%));

            width: 100%;
        }
    }

    .content-places {
        padding-top: 9rem;
        padding-bottom: 9rem;

        background-color: $white;

        @include media-queries(768px) {
            padding-top: 4.5rem;
            padding-bottom: 4.5rem;
        }

        @include media-queries(767px) {
            padding: 3rem;
        }
    }
}