/*------------------------------------------------------------------
[Banners]

Name: Banners;

Notes: All banner on the site
-------------------------------------------------------------------*/
.banner-top {
    position: relative;
    z-index: 10;

    .home & {
        margin-bottom: 6rem;

        @include media-queries(767px) {
            margin-bottom: 0rem;
        }
        
        .banner-top__book-now {
            height: 42px;
            padding: 0 4rem;

            @include media-queries(767px) {
                height: auto;
                padding-bottom: 0;
            }
        }
    }

    @include media-queries(767px) {
        padding: 0;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    &__book-now {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));

        padding: 0;
        width: 100%;

        //margin-bottom: -25px;
        //margin-bottom: 4.5rem;

        @include media-queries(767px) {
            position: relative;
            z-index: 0;
            top: 0;
            left: 0;
            @include transform(translate(0, 0));

            padding-left: 1rem;
            padding-right: 1rem;
            //margin-bottom: 0;
            margin-top: 3rem;
            //margin-bottom: 2rem;

            //&:before {
            //    content: '';
            //
            //    position: absolute;
            //    z-index: 0;
            //    left: 50%;
            //
            //    border-style: solid;
            //    width: 0;
            //    height: 0;
            //
            //    border-width: 260px 1px 1px 2560px;
            //    border-color: $background $background $blue $blue;
            //
            //    @include transform(translate(-50%, -80%));
            //}
        }
    }

    + .section-book-now {
        z-index: 45;
        margin-top: -2rem;

        @include media-queries(767px) {
            display: none;
        }
    }

    &--404 {
        margin: 0 -1rem;
        padding-top: 15rem;
        padding-bottom: 8rem;
        height: 100%;

        //background-color: $light-blue;

        //&:before {
        //    content: '';
        //
        //    top: 80px;
        //
        //    border-width: 160px 2560px 1px 1px;
        //    border-color: $background transparent transparent transparent;
        //}
    }

    &--text-only {
        position: absolute;
        z-index: 0;
        left: 0;

        width: 100%;
    }

    .container .row {
        position: relative;
    }

    &__content {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));

        width: 70%;

        color: $white;

        &--404 {
            position: relative;
            top: 0;
            left: 0;
            @include transform(translate(0, 0));

            color: $blue;
        }
    }

    &__content-left {
        position: absolute;
        top: 50%;
        right: 100%;
        @include transform(translate(50%, -50%) rotate(-90deg));
        -webkit-transform-origin: center top;
        -moz-transform-origin: center top;
        -o-transform-origin: center top;
        -ms-transform-origin: center top;
        transform-origin: center top;

        margin-right: 40px;

        font-weight: 700;
        font-size: 1.4rem;
        color: $blue;
        white-space: nowrap;
    }

    &__content-right {
        position: absolute;
        top: 50%;
        right: -40px;
        @include transform(translate(50%, -50%) rotate(-90deg));

        font-weight: 700;
        font-size: 1.4rem;
        color: $blue;
    }
}