/*------------------------------------------------------------------
[Navigation]

Name: Description;

1. [Site menu]

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Site menu]
*/
.site-menu {
    margin-top: 3rem;

    @include transition(all 0.5s ease);

    @include media-queries(768px) {
        margin-top: 2rem;
    }

    .fixed & {
        margin-top: 1.5rem;

        @include media-queries(768px) {
            margin-top: 2rem;
        }
    }

    &__container {
        text-align: center;
    }

    &__hide {
        @include opacity(0);
    }

    li {
        padding: 0 1.5rem 0 1.5rem;

        font-weight: 700;
        color: $blue;

        @include media-queries(768px) {
            padding-left: 0;
            padding-right: 0;

            color: $white;

            &.menu-item {
                display: none;
            }
        }

        &:hover {

            .sub-menu {
                visibility: visible;

                @include opacity(1);
                @include transition-delay(0s);
            }
        }
    }

    a {
        font-weight: 700;
        color: $blue;

        &:after {
            content: '';
            display: block;

            margin-top: 0.5rem;
            height: 3px;
            width: 100%;

            background-color: transparent;
        }
    }

    &__container > li:not(.site-logo):hover > a {

        &:after {
            background-color: $blue;
        }
    }

    .current-menu-item:not(.site-logo) a {
        &:after {
            content: '';
            display: block;

            margin-top: 0.5rem;
            height: 3px;
            width: 100%;

            background-color: $blue;
        }
    }
    
    .sub-menu {
        visibility: hidden;

        position: absolute;
        top: 100%;

        padding: 2.5rem;

        text-align: left;

        background-color: $blue;

        @include opacity(0);
        @include transition(visibility 0s ease 0.5s, opacity 0.5s ease);

        li {
            margin-bottom: 1.5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        a {
            color: $white;

            &::after {
                display: none !important;
            }
        }
    }
}

/*------------------------------------------------------------------
[Mobile menu]
*/
.mobile-menu {
    display: none;

    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $dark-blue;

    font-size: 2.6rem;
    color: $white;

    > div {
        float: left;

        margin: 2rem 1rem;

        font-size: 1.3rem;
        font-weight: 700;

        &:last-of-type {
            float: right;
        }
    }

    &__container {
        position: absolute;
        top: 50%;
        left: 0;
        @include transform(translate(0, -50%));

        width: 100%;
        max-height: 75%;

        overflow: auto;
    }

    li {
        margin-bottom: 1rem;

        text-align: center;

        &.current_page_parent {

            .sub-menu {
                display: block;
            }
        }
    }

    .site-logo {
        display: none;
    }

    &__close {
        margin-top: 2rem;

        font-size: 1.3rem;
        font-weight: 700;
    }

    .sub-menu {
        display: none;
        margin-top: 5px;
        margin-bottom: 10px;

        li {

            a {
                font-size: 1.8rem;
            }
        }
    }
}