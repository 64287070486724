/*------------------------------------------------------------------
[Buttons]

Name: Buttons;

Notes: All buttons on the projects
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Button]
*/
.btn {
    display: inline-block;

    cursor: pointer;

    &--send {
        //border-width: 0;
        //margin-top: 1.5rem;
        //
        //background-color: transparent;
        //

        padding: 1.3rem 2rem;

        font-weight: 700;
        font-size: 1.4rem;
        //letter-spacing: 0.100em;
        //
        //@include media-queries(767px) {
        //    font-size: 2.2rem;
        //}

        background-color: $light-blue;

        margin-right: 0;
        width: calc(20%);

        color: $white;
        text-align: center;

        cursor: pointer;

        @include media-queries(767px) {
            width: 100%;
        }
    }

    &--gallery {
        border-bottom: 3px solid $white;
        padding-bottom: 0.5rem;

        font-weight: 700;

        cursor: pointer;

        @include media-queries(767px) {
            border-bottom-width: 1.5px;
        }
    }

    &--file {
        border-bottom-width: 3px;
        border-bottom-style: solid;
        padding-bottom: 0.5rem;
        margin-right: 4rem;

        font-weight: 700;
        font-size: 1.6rem;

        + & {
            margin-right: 0;
        }

        @include media-queries(767px) {
            border-bottom-width: 1.5px;

            font-size: 1.2rem;
        }
    }
}