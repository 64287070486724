/*------------------------------------------------------------------
[Carousel]

Name: Description;

[1. Owl banner]
[2. Owl gallery]

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Owl banner]
*/
.banner-top__owl {

    &-item {
        padding-bottom: 50%;
        width: 100%;

        background: transparent no-repeat center center;
        -webkit-background-size: cover;
        background-size: cover;

        // .home & {
        //     padding-bottom: 50%;

        //     @include media-queries(767px) {
        //         padding-bottom: 100%;
        //     }
        // }

        @include media-queries(767px) {
            padding-bottom: 100%;
        }
    }

    .owl-nav {
        position: absolute;
        z-index: 2;
        top: 50%;
        @include transform(translate(0, -50%));

        width: 100%;

        div {
            position: absolute;

            margin-top: -30px;

            width: 0;
            height: 0;
            border-style: solid;

            text-indent: -99999px;

            @include media-queries(767px) {
                margin-top: -15px;
            }

            &.owl-prev {
                left: 35px;
                //margin-left: -4rem;

                width: 30px;
                height: 40px;
                background: url('../images/svg/arrow.svg') no-repeat center center;
                background-size: contain;

                @include media-queries(767px) {
                    left: 20px;

                    width: 15px;
                    height: 20px;
                }
            }

            &.owl-next {
                right: 35px;
                //margin-right: -4rem;

                width: 30px;
                height: 40px;
                background: url('../images/svg/arrow.svg') no-repeat center center;
                background-size: contain;

                @include transform(rotate(180deg));

                @include media-queries(767px) {
                    right: 20px;

                    width: 15px;
                    height: 20px;
                }

            }
        }
    }
}

/*------------------------------------------------------------------
[2. Owl gallery]
*/
.owl-gallery {
    float: left;

    width: 100%;

    img {
        margin: 0 auto;
        width: auto;
        max-width: 100%;
        max-height: calc(100vh - 40px - 18px - 40px - 40px - 18px - 40px );
    }

    .owl-nav {
        position: absolute;
        z-index: 2;
        top: 50%;
        @include transform(translate(0%, -50%));

        width: 100%;

        div {
            position: absolute;

            margin-top: -30px;

            width: 0;
            height: 0;
            border-style: solid;

            text-indent: -99999px;

            @include media-queries(767px) {
                margin-top: -15px;
            }

            &.owl-prev {
                left: -35px;
                margin-left: -4rem;

                width: 30px;
                height: 40px;
                background: url('../images/svg/arrow.svg') no-repeat center center;
                background-size: contain;

                @include media-queries(767px) {
                    left: -20px;

                    width: 15px;
                    height: 20px;
                }
            }

            &.owl-next {
                right: -35px;
                margin-right: -4rem;

                width: 30px;
                height: 40px;
                background: url('../images/svg/arrow.svg') no-repeat center center;
                background-size: contain;

                @include transform(rotate(180deg));

                @include media-queries(767px) {
                    right: -20px;

                    width: 15px;
                    height: 20px;
                }

            }
        }
    }
}

/*------------------------------------------------------------------
[2. Owl places]
*/
.owl-places {

    .owl-item {

        img {
            width: auto;
            max-width: 100%;
        }
    }
}