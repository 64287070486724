/*------------------------------------------------------------------
[Template Contact]

Name: Template Contact;

1. [Section spacing]
2. [Google map]

-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Section spacing]
*/
.page-template-tpl-contact {

    .bg-white {
        background-color: $white;
    }

    .section-content {

        &__content {
            @include media-queries(767px) {
                padding: 0  3rem;
            }
        }
    }

    .section-content-small-big {
        margin-top: -7rem;
        padding-top: 14rem;
        padding-bottom: 8rem;

        @include media-queries(767px) {
            margin-top: -3.5rem;
            padding: 7rem 3rem 3.5rem 3rem;
        }
    }

    .section-map {
        margin-top: -1rem;
        padding-bottom: 8rem;

        @include media-queries(767px) {
            padding: 0 3rem 3.5rem 3rem;
        }
    }

    .section-content-2-column {
        padding-top: 8rem;
        padding-bottom: 8rem;

        @include media-queries(767px) {
            padding: 3.5rem 3rem;
        }
    }
}

/*------------------------------------------------------------------
[2. Google map]
*/
.acf-map {
    margin-bottom: 9rem;
    padding-bottom: 50%;
    width: 100%;

    @include media-queries(767px) {
        margin-bottom: 3.5rem;
    }
}