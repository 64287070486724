/*------------------------------------------------------------------
[Forms]

Name: Forms;

Notes: All forms
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Form contact 7]
*/
::-webkit-input-placeholder {
    color: inherit;
    @include opacity(1);/* for chrome */
}

:-moz-placeholder { /* Firefox 18- */
    color: inherit;
    @include opacity(1);
}

::-moz-placeholder {  /* Firefox 19+ */
    color: inherit;
    @include opacity(1);
}

:-ms-input-placeholder {
    color: inherit;
    @include opacity(1);
}

input,
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: block;

    border-width: 0;
    @include border-radius(0);
    padding: 0;
    margin: 0;
    width: 100%;

    background-color: transparent;

    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}

input[type="submit"] {
    width: auto;
}

.wpcf7 {
    font-size: 1.8rem;

    @include media-queries(767px) {
        font-size: 1.3rem;
    }

    .wpcf7-form-control-wrap {
        display: block;

        margin-bottom: 2.5rem;

        @include media-queries(767px) {
            border-bottom-width: 1rem;
        }
    }
    
    [role="alert"] {
        margin-top: 0.5rem;
    }
    
    .wpcf7-response-output {
        margin: 4rem 0 0 0;
        padding: 1.5rem;

        text-align: center;

        @include media-queries(767px) {
            margin-top: 4rem;
        }
    }

    input:not([type="submit"]) {
        border-bottom: 3px solid $blue;

        padding: 1.5rem 0;

        @include media-queries(767px) {
            border-bottom-width: 1.5px;

            padding: 1rem 0;
        }
    }

    textarea {
        border-bottom: 3px solid $blue;

        padding: 1.5rem 0;

        @include media-queries(767px) {
            border-bottom-width: 1.5px;

            padding: 1rem 0;
        }
    }

    select {
        border-bottom: 3px solid $blue;

        padding: 1.5rem 0;

        @include media-queries(767px) {
            border-bottom-width: 1.5px;

            padding: 1rem 0;
        }
    }
}