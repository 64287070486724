/*------------------------------------------------------------------
[Default theme]

Name: Description;

Notes:
-------------------------------------------------------------------*/

body, html {
    height: 100%;
    width: 100%;
}

html {
    font-family: $regular;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
}

body {
    background: $background url("../images/background-image.jpg") repeat top center;
}

main {
    position: relative;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

/*------------------------------------------------------------------
[Section styles]
*/
main {

    section {
        position: relative;

        .container {
            position: relative;
            z-index: 5;
        }

        &:before,
        &:after {
            position: absolute;
            z-index: 0;
            left: 50%;

            border-style: solid;
            width: 0;
            height: 0;

            display: none;
        }

        &:before {
            top: 1px;
            @include transform(translate(-50%, -50%));
        }

        &:after {
            bottom: 1px;
            @include transform(translate(-50%, 50%));
        }

        &.blue {
            color: $white;

            background-color: $blue;

            &:before {
                content: '';

                border-width: 1px 1px 160px 2560px;
                border-color: transparent transparent $blue transparent;
            }

            &:after {
                content: '';

                border-width: 560px 2560px 1px 1px;
                border-color: $blue transparent transparent transparent;
            }
        }

        &.light-blue {
            color: $white;

            background-color: $light-blue;

            &:before {
                content: '';

                border-width: 240px 1px 1px 2560px;
                border-color: transparent transparent transparent $light-blue;
            }

            &:after {
                content: '';

                border-width: 240px 2560px 1px 1px;
                border-color: $light-blue transparent transparent transparent;
            }
        }

        &.contact-01 {

            &:before {
                border-color: $background $background $blue $background !important;
            }

            &:after {
                border-color: $blue $light-blue $light-blue $light-blue !important;
            }
        }

        &.contact-02 {

            &:before {
                border-color: $light-blue $blue $blue $blue !important;
            }

            &:after {
                border-color: $light-blue $background $background $background !important;
            }
        }

        &.contact-03 {
            padding-bottom: 14rem;
            padding-top: 8rem;

            @include media-queries(767px) {
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;
            }

            &:before {
                border-width: 260px 1px 1px 2560px;
                border-color: $background $light-blue $light-blue $light-blue;
            }
        }

        &.blue-trapeze-right {
            color: $white;

            background-color: $blue;

            &:before {
                top: 1px;
                @include transform(translate(-50%, -70%));
            }

            &:after {
                bottom: 1px;
                @include transform(translate(-50%, 70%));
            }

            &:before {
                content: '';

                border-width: 260px 1px 1px 2560px;
                border-color: transparent transparent transparent $blue;

            }

            &:after {
                content: '';

                border-width: 260px 2560px 1px 1px;
                border-color: $blue transparent transparent transparent;

            }

            &.prev-banner {

                &:before {
                    content: '';

                    border-color: $background $background $blue $blue;

                    @include transform(translate(-50%, -100%));
                }

                &:after {

                    .home & {
                        display: none;
                    }
                }
            }
        }

        &.blue-trapeze-left {
            color: $white;

            background-color: $blue;

            &:before {
                top: 1px;
                @include transform(translate(-50%, -70%));
            }

            &:after {
                bottom: 1px;
                @include transform(translate(-50%, 70%));

                @include media-queries(767px) {
                    @include transform(translate(-50%, 50%));
                }
            }

            &:before {
                content: '';

                border-width: 1px 1px 160px 2560px;
                border-color: transparent transparent $blue transparent;
            }

            &:after {
                content: '';

                border-width: 1px 2560px 560px 1px;
                border-color: transparent $blue transparent transparent;
            }

            &.prev-banner {
                margin-top: -4rem;

                @include media-queries(767px) {
                    margin-top: 0;
                }

                &:before {
                    content: '';

                    border-width: 1px 1px 160px 2560px;
                    border-color: $background $background $blue $background;
                }
            }

            &.next-silver {
                margin-top: -4rem;

                &:after {
                    border-color: $blue $blue $background $background;
                }
            }

            &.next-light-blue {

                &:after {
                    border-color: $blue $blue $light-blue $light-blue;
                }
            }

            &.small-bottom {
                padding-bottom: 3rem;
            }
        }

        &.light-blue-trapeze-left {
            color: $white;

            background-color: $light-blue;

            a {
                border-bottom-color: $white;
            }

            &:before {
                top: 1px;
                @include transform(translate(-50%, -70%));
            }

            &:after {
                bottom: 1px;
                @include transform(translate(-50%, 70%));

                @include media-queries(767px) {
                    @include transform(translate(-50%, 50%));
                }
            }

            &:before {
                content: '';

                border-width: 1px 1px 280px 2560px;
                border-color: transparent transparent $light-blue transparent;
            }

            &:after {
                content: '';

                border-width: 1px 2560px 280px 1px;
                border-color: transparent $light-blue transparent transparent;
            }

            &.prev-blue {

                &:before {
                    border-color: $blue $blue $light-blue $blue;
                }
            }

            &.next-silver {

                &:after {
                    border-color: $light-blue $light-blue $background $background;
                }
            }

            &.next-silver-more {
                padding-bottom: 12rem;
                margin-bottom: -8rem;

                @include media-queries(767px) {
                    padding-bottom: 9rem;
                }

                &:after {
                    @include transform(translate(-50%, 100%));
                }
            }

            &.prev-silver {

                &:before {
                    border-color: $background $background $light-blue $background;
                }
            }

            &.banner-rooms {
                &:before {
                    top: -1px;
                    @include transform(translate(-50%, 0%));
                }
            }

            &.small-section {
                margin-bottom: -7rem;

                @include media-queries(768px) {
                    margin-bottom: -2rem;
                }

                @include media-queries(767px) {
                    margin-bottom: 3rem;
                }

                &:before {
                    top: 1px;
                    @include transform(translate(-50%, -50%));
                }

                &:after {
                    bottom: 1px;
                    @include transform(translate(-50%, 70%));
                }
            }

            &.text-blue {
                color: $blue;

                a {
                    border-bottom-color: $blue;
                }
            }

            &.small-top {
                padding-top: 8rem;
            }
        }

        &.light-blue-trapeze-right {
            color: $white;

            background-color: $light-blue;

            a {
                border-bottom-color: $white;
            }

            &:before {
                top: 1px;
                @include transform(translate(-50%, -70%));
            }

            &:after {
                bottom: 1px;
                @include transform(translate(-50%, 70%));

                @include media-queries(767px) {
                    @include transform(translate(-50%, 50%));
                }
            }

            &:before {
                content: '';

                border-width: 1px 1px 280px 2560px;
                border-color: transparent transparent $light-blue transparent;
            }

            &:after {
                content: '';

                border-width: 360px 2560px 1px 1px;
                border-color: $light-blue transparent transparent transparent;
            }

            &.prev-blue {

                &:before {
                    border-color: $blue $blue $light-blue $blue;
                }
            }

            &.next-silver {

                &:after {
                    border-color: $light-blue $background $background $background;
                }
            }

            &.prev-silver {

                &:before {
                    border-color: $background $background $light-blue $background;
                }
            }

            &.small-section {
                margin-bottom: -7rem;

                @include media-queries(767px) {
                    margin-bottom: 3rem;
                }

                &:before {
                    top: 1px;
                    @include transform(translate(-50%, -50%));
                }

                &:after {
                    bottom: 1px;
                    @include transform(translate(-50%, 70%));
                }
            }

            &.text-blue {
                color: $blue;

                a {
                    border-bottom-color: $blue;
                }
            }

            &.small-bottom {
                padding-bottom: 3rem;
            }
        }

        &.silver-trapeze-blue-light-bg {
            padding-top: 0;

            background-color: $background;

            &:before {
                top: 1px;
                @include transform(translate(-50%, -50%));
            }

            &:after {
                bottom: 1px;
                @include transform(translate(-50%, 0.5%));

                @include media-queries(768px) {
                    display: none;
                }

                @include media-queries(767px) {
                    display: block;
                }
            }

            &:before {
                content: '';

                border-width: 1px 2560px 400px 1px;
                border-color: transparent $light-blue transparent transparent;
            }

            &:after {
                content: '';

                border-width: 1px 1px 500px 2560px;
                border-color: transparent transparent $light-blue transparent;
            }

            &.prev-light-blue {

                &:before {
                    border-color: $light-blue $light-blue $background $background;
                }
            }
        }

        &.section-text-only {
            color: $blue;

            //padding-top: 12rem;
            //padding-bottom: 12rem;

            .section-text-only__trapeze {
                position: absolute;
                top: 450px;
                left: 0;

                width: 100%;
                height: 50%;

                background-color: $light-blue;

                &:before,
                &:after {
                    content: '';

                    position: absolute;
                    z-index: 0;
                    left: 0;
                }

                &:before {
                    top: 0;

                    padding-bottom: 8rem;
                    width: 100%;

                    background-color: inherit;
                    @include transform(skew(0deg, -1.7deg) translate(0px, -50%));
                }

                &:after {
                    bottom: 0;

                    padding-bottom: 28rem;
                    width: 100%;

                    background-color: inherit;
                    @include transform(skew(0deg, 6deg) translate(0px, 50%));
                }
            }
        }
    }
}
