$burgerColor: 				#fff; 		// Color line
$burgerWidth: 				29px; 		// Width button
$burgerHeight: 				24px; 		// Height button
$burgerLine: 				4px; 		// Size line
$burgerWidthM: 				20px; 		// Width button
$burgerHeightM:				16px; 		// Height button
$burgerLineM: 				2px; 		// Size line
$burgerLinePosition: round(($burgerHeight / 3) + 2); // Calculate line position
$burgerLinePositionM: round(($burgerHeightM / 3) + 2); // Calculate line position

.btn-line-menu {
    position: absolute;
    z-index: 56;
    top: 37px;
    right: 37px;

    cursor: pointer;

    @include media-queries(768px) {
        top: 30px;
        right: 30px;
    }

    &__icon {
        position: relative;

        cursor: pointer;

        width:     $burgerWidth;
        height:    $burgerHeight;

        @include transform(rotate(0deg));
        @include transition(.5s ease-in-out);

        display: inline-block;
        vertical-align: middle;

        @include media-queries(767px) {
            width:     $burgerWidthM;
            height:    $burgerHeightM;
        }

        span {
            display: block;

            opacity: 1;

            position: absolute;

            left: 0;

            height:    $burgerLine;
            width:     100%;

            background-color: $burgerColor;

            @include transform(rotate(0deg));
            @include transition(.25s ease-in-out);

            @include media-queries(767px) {
                height:    $burgerLineM;
            }

            .active & {
                background-color: $content;
            }

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2), &:nth-child(3) {
                top: $burgerLinePosition;

                @include media-queries(767px) {
                    top: $burgerLinePositionM;
                }
            }

            &:nth-child(4) {
                top: $burgerLinePosition * 2;

                @include media-queries(767px) {
                    top: $burgerLinePositionM * 2;
                }
            }
        }
    }

    &.active .btn-line-menu__icon span:nth-child(1) {
        top:     $burgerLinePosition;
        left:    50%;

        width: 0;

        @include media-queries(767px) {
            top: $burgerLinePositionM;
        }
    }

    &.active .btn-line-menu__icon span:nth-child(2) {
        @include transform(rotate(45deg));
    }

    &.active .btn-line-menu__icon span:nth-child(3) {
        @include transform(rotate(-45deg));
    }

    &.active .btn-line-menu__icon span:nth-child(4) {
        top:      $burgerLinePosition;
        left:     50%;

        width: 0;

        @include media-queries(767px) {
            top:      $burgerLinePositionM;
        }
    }
}