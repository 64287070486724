/*------------------------------------------------------------------
[Footer]

Name: Footer;

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Footer]
*/
footer {
    padding-top: 8rem;
    padding-bottom: 8rem;

    background-color: $footer;

    font-size: 1.5rem;
    line-height: 1.8;
    color: $light-silver;
    text-align: center;

    @include media-queries(767px) {
        padding: 3.5rem 3rem;

        font-size: 1.2rem;
    }

    p {
        margin-bottom: 3rem;

        @include media-queries(767px) {
            margin-bottom: 1.5rem;
        }

        &:last-of-type {
            margin-bottom: 0;

            color: $silver;

            a {
                color: $silver;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    a {
        color: $light-blue;
    }
}

/*------------------------------------------------------------------
[Site logo - footer]
*/
.site-logo-footer {
    display: inline-block;

    color: $white;
    font-size: 2.5rem;

    @include media-queries(767px) {
        font-size: 2rem;
    }
}