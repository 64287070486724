/*------------------------------------------------------------------
[Mixins]

media-queries:  @include media-queries($width);
clearfix:       @include clearfix();
opacity:        @include opacity();
border-radius:  @include border-radius();

Notes: All mixins
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[media-queries]
*/
@mixin media-queries($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

/*------------------------------------------------------------------
[clearfix]
*/
%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

/*------------------------------------------------------------------
[opacity]
*/
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

/*------------------------------------------------------------------
[border-radius]
*/
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

/*------------------------------------------------------------------
[transition]
*/
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

/*------------------------------------------------------------------
[transform]
*/
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

/*------------------------------------------------------------------
[rotate]
*/
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

/*------------------------------------------------------------------
[scale]
*/
@mixin scale($scale) {
    @include transform(scale($scale));
}

/*------------------------------------------------------------------
[translate]
*/
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

/*------------------------------------------------------------------
[skew]
*/
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

/*------------------------------------------------------------------
[transform origin]
*/
//@mixin transform-origin ($origin) {
//    moz-transform-origin: $origin;
//    -o-transform-origin: $origin;
//    -ms-transform-origin: $origin;
//    -webkit-transform-origin: $origin;
//    transform-origin: $origin;
//}

/*------------------------------------------------------------------
[box-sizing]
*/
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}