/*------------------------------------------------------------------
[Header]

Name: Description;

1. [Header]
2. [Site logo]
3. [Nav lang]

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Header]
*/
header {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;

    @include box-sizing(border-box);
    padding: 0 1rem 3rem 1rem;
    width: 100%;
    //height: 161px;

    font-size: 1.6rem;
    text-align: left;

    background: $background url("../images/background-image.jpg") repeat top center;

    @include transition(all 0.5s ease);

    @include media-queries(768px) {
        padding: 0 1rem 2rem 1rem;

        //height: 60px;

        font-size: 1.3rem;
    }

    .container {
        position: relative;
    }

    &.fixed {
        padding: 0 1rem 1rem 1rem;
        //height: 96px;

        background-position: center -40px;

        @include media-queries(768px) {
            padding: 0 1rem 2rem 1rem;

            //height: 60px;
        }
    }

    .container {

        @include media-queries(768px) {
            display: none;
        }

        &.mobile {
            display: none;

            @include media-queries(768px) {
                display: inline-block;

                width: auto !important;
            }
        }
    }
}

/*------------------------------------------------------------------
[Site logo]
*/
.bar-site-logo {
    padding: 3rem 0;

    background-color: $blue;

    @include transition(all 0.5s ease);

    @include media-queries(768px) {
        position: absolute;
        z-index: 0;

        padding: 1.5rem 0;
        width: 100%;
    }

    @include media-queries(480px) {
        padding: 2.15rem 0;
    }

    .fixed & {
        padding: 2rem 0;

        @include media-queries(768px) {
            padding: 2.15rem 0;
        }
    }

    &__icon {
        color: $white;
        font-size: 3rem;

        @include transition(all 0.5s ease);

        @include media-queries(768px) {
            font-size: 2.5rem;
        }

        @include media-queries(480px) {
            font-size: 1.25rem;
        }

        .fixed & {
            font-size: 2.5rem;

            @include media-queries(768px) {
                font-size: 1.25rem;
            }
        }
    }
}

.site-logo {
    display: none;

    width: 100%;

    text-align: center;


    @include media-queries(768px) {
        padding: 0;
        width: auto;
    }

    a {
        position: relative;
        display: inline-block;

        font-size: 2rem;
        color: $dark-blue;

        margin-bottom: 1.5rem;

        @include transition(all 0.5s ease);

        @include media-queries(768px) {
            margin-bottom: 0;
        }

        @include media-queries(375px) {
            font-size: 1.5rem;
        }

        &:before {
            content: "\e900";
            color: $dark-blue;
        }

        .fixed & {
            margin-bottom: 1rem;

            @include media-queries(768px) {
                margin-bottom: 0;
            }
        }
    }
}

/*------------------------------------------------------------------
[Nav lang]
*/
header .nav-lang {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    list-style: none;

    &:hover {

        & + .nav-lang--contextmenu {
            visibility: visible;

            @include opacity(1);
            @include transition-delay(0s);
        }
    }

    li {
        display: none;

        &.current-lang {
            display: block;

            background-color: $background;

            a {

                &:hover {
                    text-decoration: none;
                }

                &:after {
                    content: '';

                    display: inline-block;

                    height: 7px;
                    width: 7px;
                    margin-left: 10px;

                    border-right: 1px solid $blue;
                    border-bottom: 1px solid $blue;

                    @include transform(rotate(45deg) translate3d(-4px, 0, 0));
                }
            }
        }

        a {
            display: block;

            padding: 5px 10px;

            font-size: 1.2rem;
            color: $blue;
            text-transform: uppercase;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--contextmenu {
        visibility: hidden;
        z-index: 0;

        margin-top: 22px;

        background-color: $blue;

        @include opacity(0);
        @include transition(visibility 0s ease 0.5s, opacity 0.5s ease);

        &:hover {
            visibility: visible;

            @include opacity(1);
            @include transition-delay(0s);
        }

        li {
            display: block;

            &.current-lang {
                display: none;

                a {

                    &:after {
                        display: none;
                    }
                }
            }

            a {
                color: $white;
                padding-right: 17px;
            }
        }
    }
}

.mobile-menu .nav-lang {
    position: absolute;
    bottom: 0;
    left: 0;

    @include box-sizing(border-box);

    width: 100%;
    padding: 10px;

    text-align: center;

    li {
        display: inline-block;

        margin-bottom: 0;

        &.current-lang {

            a {
                text-decoration: underline;
            }
        }

        a {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}