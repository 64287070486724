/*------------------------------------------------------------------
[tpl Gallery]

Name: tpl Gallery;

[1. Gallery]

-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Gallery]
*/
.gallery {
    padding-top: 8rem;
    padding-bottom: 8rem;

    @include media-queries(767px) {
        padding: 3.5rem 3rem;
    }

    &__trapeze {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 400px;

        background-color: $light-blue;

        &:before,
        &:after {
            content: '';

            position: absolute;
            z-index: 0;
            left: 50%;
        }

        &:before {
            top: 1px;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 180px 2560px;
            border-color: transparent transparent $light-blue transparent;

            @include transform(translate(-50%, -100%));
        }

        &:after {
            bottom: 1px;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 2560px 580px 0;
            border-color: transparent $light-blue transparent transparent;

            @include transform(translate(-50%, 100%));
        }
    }

    &__item {
        padding-top: 1rem;
        padding-bottom: 1rem;

        cursor: pointer;
    }

    &__img {
        padding-bottom: 60%;
        width: 100%;

        background: transparent no-repeat center center;
        -webkit-background-size: cover;
        background-size: cover;
    }
}