/*------------------------------------------------------------------
[Variables]

Body copy:      1.2em/1.6em Verdana, Helvetica, Arial, Geneva, sans-serif;

Notes:  All variable on the project
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Color codes]

Background: #ffffff (white)
Content:    #000000 (black)
Header h1:  #9caa3b (green)
Header h2:  #ee4117 (red)
Footer:     #4d4d4d (dark silver)

a (standard):   #0040b6 (dark blue)
a (visited):    #5999de (light blue)
a (active):     #cc0000 (pink)

White: #ffffff;
-------------------------------------------------------------------*/

$background: #ebebeb;
$content: #ffffff;
$footer: #001b46;

$white: #ffffff;
$orange: #ea5920;
$blue: #1b607d;
$dark-blue: #002e52;
$light-blue: #0090ca;
$silver: #808080;
$light-silver: #b3b3b3;

$green: #03da85;
