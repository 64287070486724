/*------------------------------------------------------------------
[Default page]

Name: Default page;

1. [Simple content]

-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[1. Simple content]
*/
.content-image {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;

    @include media-queries(767px) {
        padding: 2rem 3rem;
    }

    &:last-of-type {
        padding-bottom: 15rem;

        @include media-queries(768px) {
            padding-bottom: 7.5rem;
        }

        @include media-queries(767px) {
            padding-bottom: 3.5rem;
        }
    }

    &.big-section {
        padding-bottom: 25rem;

        @include media-queries(768px) {
            padding-bottom: 1.25rem;
        }
    }

    &.small-section {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__content {
        @include media-queries(767px) {
            padding-bottom: 2rem;

            p.empty {
                display: none;

                //+ p.empty {
                //    display: block;
                //}
            }
        }
    }

    &__img {
        position: relative;

        img {
            position: absolute;

            @include media-queries(767px) {
                position: relative;
            }
        }

        &--left {

            @include media-queries(767px) {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            img {
                right: 0;

                @include media-queries(767px) {
                    float: right;
                }
            }
        }
    }
}
