@import "../../node_modules/bourbon/core/bourbon";
/*------------------------------------------------------------------
[56 Degrees]

Author:     Radek Wysocki;
Version:    1.0.0
Notes:
-------------------------------------------------------------------*/

@import "base/html5reset-1.6.1";
@import "base/normalize";
@import "helpers/mixins";
@import "base/variables";
@import "base/typography";

@import "vendors/owl.carousel.min";
@import "vendors/animate";

@import "vendors/wordpress-core";
@import "layout/flexboxgrid";
@import "layout/header";
@import "themes/theme";
@import "layout/sidebar";
@import "layout/footer";

@import "components/navigation";
@import "components/burger-menu";
@import "components/forms";
@import "components/book-now";
@import "components/banners";
@import "components/buttons";
@import "components/carousel";
@import "components/popup";
@import "vendors/datepicker";

@import "themes/default-page";
@import "themes/tpl-contact";
@import "themes/tpl-gallery";
@import "themes/single";
@import "themes/archive";
@import "pages/home";

@import "custom.css";