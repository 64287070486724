/*------------------------------------------------------------------
[Popups]

Name: Popups;

Notes:
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Popup gallery]
*/
.popup-gallery {
    display: none;
    @include box-sizing(border-box);

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    padding-top: 4rem;
    padding-bottom: 4rem;
    height: 100%;
    width: 100%;

    background-color: rgba(5, 28, 44, 0.8);

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
    }

    &__close {
        margin-bottom: 4rem;

        font-family: $bold;
        font-size: 1.8rem;
        color: $white;
        text-align: center;

        cursor: pointer;
    }

    &__count {
        margin-top: 4rem;

        font-family: $bold;
        font-size: 1.8rem;
        color: $white;
        text-align: center;
    }
}